import React, { FC, useState } from "react";
import { Redirect } from "react-router-dom";
import SettingsTitle from "./settingsTitle";
import CommonModal from "../../../shared/components/commonModal";
import ModalButtons from "../../../shared/actionModals/shared/modalButtons";

type Props = {
  message: string;
  onDelete: () => Promise<void>;
};

const SettingsDelete: FC<Props> = ({ message, onDelete }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [redirect, setRedirect] = useState<boolean>(false);
  
  const save = () => {
    onDelete()
      .then(() => {
        setRedirect(true);
      })
      .catch((error) =>{
        setErrors([error as string]);
      });
  }

  if (redirect) return <Redirect to={`../list`} />;
  return (
    <CommonModal
      show={true}
      onHide={()=>setRedirect(true)}
      title={message}
      buttons={<ModalButtons close={()=>setRedirect(true)} save={save} saveLabel="Yes" />}
    >
      <SettingsTitle title="" errors={errors} />
      {message}
    </CommonModal>
  );
}
export default SettingsDelete;
