import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Role, User } from "../../../../../models";
import isDuplicate from "../../shared/helpers/isDuplicate";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsAction, SettingsParams, SettingsType } from "../types";
import UserForm from "./userForm";
import { getAllUsers } from "../../../../../services/get.data.service";
import SettingsTable from "../../shared/table/table";
import SettingsTitle from "../../shared/settingsTitle";
import SettingsDelete from "../../shared/settingsDelete";
import { useUserContext } from "../../../../../context/userContextProvider";
import { deleteUser } from "../../../../../services/delete.data.service";

const UserSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [data, setData] = useState<User[] | null>(null);
  const { action }: SettingsParams = useParams();
  const { state :{ user: loggedInUser }} = useUserContext();

  const loadData = (): void => {
    getAllUsers().then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  
  const existingUser = data?.find((user) => user.id === Number(id))

  const mapPartialRow = (user: User): ResultsTableRowData => {
    const teams = user.teams.map(
      (team, index: number) => `${team.teamName}${user.teams.length - 1 === index ? "" : ", "}`
    );
    return {
      id: user.id,
      data: [
        user.emailAddress,
        `${user.firstName} ${user.lastName}`,
        user.roles.includes(Role.Manager) ? "Yes" : "No",
        user.roles.includes(Role.SystemAdmin) ? "Yes" : "No",
        teams,
      ]
    }

  };
  const activeRows: ResultsTableRowData[] | null =
    data &&
    data.filter((user)=>{
      return !user.deactivated && user.azureId;
    }).map((user) => {
      const row = mapPartialRow(user);
      row.data.push(
        user.id !== loggedInUser?.id && <Link key={`edit-${user.id}`} to={`/settings/users/delete/${user.id}`}>
          Deactivate
        </Link>,
        <Link key={`edit-${user.id}`} to={`/settings/users/edit/${user.id}`}>
          Edit
        </Link>,
      );
      return row;
    });

  const inactiveRows: ResultsTableRowData[] | null =
    data &&
    data.filter((user)=>{
      return !user.deactivated && !user.azureId;
    }).map((user) => {
      const row = mapPartialRow(user);
      row.data.push(
        user.id !== loggedInUser?.id && <Link key={`edit-${user.id}`} to={`/settings/users/delete/${user.id}`}>
          Delete
        </Link>,
        <Link key={`edit-${user.id}`} to={`/settings/users/edit/${user.id}`}>
          Edit
        </Link>);
      return row;
    });

  const deactivatedRows: ResultsTableRowData[] | null =
    data &&
    data.filter((user)=>{
      return user.deactivated;
    }).map((user) => {
      const row = mapPartialRow(user);
      row.data.push(
        "",
        <Link key={`edit-${user.id}`} to={`/settings/users/edit/${user.id}`}>
          Activate
        </Link>);
      return row;
    });

  return (
    <>
      <SettingsView
        headers={["Email", "Name", "Manager", "System Admin", "Teams", "", ""]}
        rows={activeRows}
        sortColumn={1}
        titleSingle="User"
        titlePlural="Active Users"
        type={SettingsType.users}
      >
        {data !== null && action !== SettingsAction.delete && (
          <UserForm
            isDuplicate={(value): boolean => {
              return isDuplicate(data.map((item)=>{
                return {
                  id: item.id,
                  value: item.emailAddress
                };              
              }), value, Number(id));
            }}
            existingUser={existingUser}
            load={loadData}
          />
        )}
        {action === SettingsAction.delete && existingUser !== undefined &&
          <SettingsDelete 
            message={`Are you sure you want to ${existingUser.azureId ? "deactivate" : "delete"} this user: ${existingUser.emailAddress}`}
            onDelete={() => {
              return deleteUser(existingUser.id)
              .then(() => {
                loadData();
                return;
              });
            }}
          />
        }
      </SettingsView>
      {action === SettingsAction.list && inactiveRows &&
      <>
        <SettingsTitle title="Inactive Users List"></SettingsTitle>
          <SettingsTable 
            ariaLabel="Inactive Users List"
            headers={["Email", "Name", "Manager", "System Admin", "Teams", "", ""]}
            rows={inactiveRows}
          />
        </>
      }
      {action === SettingsAction.list && deactivatedRows &&
      <>
        <SettingsTitle title="Deactivated Users List"></SettingsTitle>
          <SettingsTable 
            ariaLabel="Deactivated Users List"
            greyedOut
            headers={["Email", "Name", "Manager", "System Admin", "Teams", "", ""]}
            rows={deactivatedRows}
          />
        </>
      }
    </>
  );
};
export default UserSettings;
