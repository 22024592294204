export enum SettingsType {
  classifications = "/settings/classifications",
  clients = "/settings/acds",
  emailaccounts = "/system-settings/emailaccounts",
  groups = "/system-settings/groups",
  teams = "/settings/teams",
  transferForms = "/settings/transferforms",
  systemAccounts = "/settings/systemaccounts",
  users = "/settings/users"
}

export enum SettingsAction {
  create = "create",
  delete = "delete",
  edit = "edit",
  list = "list",
}

export type SettingsParams = {
  type: SettingsType;
  action:SettingsAction;
  id?: string;
};

export interface ResultsTableRowData {
  id: number|null;
  data: any;
}