import React, { FC } from "react";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { Menu, MenuItem } from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { FilterName, FiltersAction } from "../../../context/teamQ/reducers/filtersReducer";

export type selectOptionsType = { id: string; label: string };
 
type Props = {
  toggleHide: () => void;
}

export const ChooseFilters: FC<Props> = ({toggleHide}) => {
  const {
    state: { filters },
    dispatch: { filters: filtersDispatch },
  } = useTeamQContext();

  const unShownFilters = Object.keys(filters).filter((key: string) => !filters[key as FilterName].show);

  return (
    <div className="add-filter">
      <PopupState variant="popover">
        {(popupState): JSX.Element => (
          <> 
            <div className="row">
              <div className="col-sm-6">
                <button 
                  className="fas fa-minus btn btn-light btn-sm mb-3 p-2" 
                  onClick={toggleHide}
                  aria-label="Hide filters"
                >
                </button>
                </div>
                {unShownFilters.length > 0 && (
                  <div className="col-sm-6">
                    <button 
                      className="fas fa-plus btn btn-light btn-sm mb-3 p-2" 
                      {...bindTrigger(popupState)}
                      aria-label="Add filter"
                    >
                    </button>
                  </div>
                )}
            </div>
            {unShownFilters.length > 0 && (
              <Menu style={{ left: 40, top: 10 }} {...bindMenu(popupState)}>
                {unShownFilters.map((key: string, index: number) => {
                  const name = key as FilterName;
                  return (
                    <MenuItem
                      onClick={(): void => {
                        filtersDispatch({
                          type: FiltersAction.SHOW,
                          filterName: name,
                        });
                        popupState.close();
                      }}
                      key={index}
                    >
                      {filters[key as FilterName].label}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </>
        )}
      </PopupState>
    </div>
  );
};
