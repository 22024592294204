import React, { FC } from "react";

type Props = {
  existingTitle?: string;
};

const SettingsFormInfo: FC<Props> = ({ existingTitle, children }) => {
  return (
    <>
      {existingTitle && (
        <div className="col-sm-5 mb-3">
          <div className="settings-existing-info p-3">
            <div className="settings-title">{existingTitle}</div>
            <div className="settings-text">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};
export default SettingsFormInfo;
