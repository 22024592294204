import React, { FC } from "react";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import { FormInfo } from "../../shared/types";
import { InputProps } from "../../shared/fields/searchPatternsInput";

type Props = {
  formInfo?: FormInfo; 
  inputField: (props: InputProps) => JSX.Element;
  title: JSX.Element
};

const TransferFormSearchPatternsForm: FC<Props> = ({ formInfo, inputField, title }) => { 
  return (
    <>
      {title}
      <div className="row"> 
        <SettingsFormInfo existingTitle={formInfo?.title} />
        <div className="col-sm-7" style={{minWidth: "45rem"}}> 
          <div className="content-container p-3">
            {inputField({label: "Attachment Name Patterns", property: "attachmentNamePatterns"})}
            {inputField({label: "Subject Patterns", property: "subjectPatterns"})}
          </div>
        </div>
      </div>
    </>
  );
};
export default TransferFormSearchPatternsForm;
