import React, { Component, createContext } from "react";
import * as _ from "lodash";
import { getACDs, getActiveUsers, getDashboardData } from "../services/get.data.service";
import { getAlphabetisedDashboardCasesByUsersList } from "../portal/shared/helpers/sorting";
import { User } from "../models";
import { 
  DashboardState, 
  DashboardACD, 
  DashboardUser, 
  DashboardCaseByUser, 
  DashboardContext 
} from "./dashboard/dashboard";

export interface Props {
  children: React.ReactNode;
}

const Context = createContext<any>(null);

export class DashboardContextProvider extends Component<Props> {
  state: DashboardState = {
    acds: [],
    casesByUser: [],
    dashboardData: [],
    filters: {
      period: "all",
      type: "classification",
      status: "open",
    },
    isLoading: true,
    selectedACDs: [],
    selectedUsers: [],
    users: [],
  };

  async componentDidMount(): Promise<void> {
    this.restoreFilters(this.loadData);
  }

  changeACDs = async (acds: DashboardACD[]): Promise<void> => {
    this.setState({ selectedACDs: acds }, () => {
      this.updateData();
      this.storeFilters();
    });
  };

  changeUsers = async (users: DashboardUser[]): Promise<void> => {
    this.setState({ selectedUsers: users }, () => {
      this.updateData();
      this.storeFilters();
    });
  };

  clearData = (): void => {
    this.setState(
      {
        selectedACDs: [],
        selectedUsers: [],
        filters: {
          period: "all",
          type: "classification",
          status: "open",
        },
      },
      () => {
        this.storeFilters();
        this.updateData();
      }
    );
  };

  async getAcds(): Promise<DashboardACD[]> {
    return await getACDs().then((res) => {
      return _.map(res.data, (d) => {
        return { id: d.clientId, label: d.clientName };
      });
    });
  }

  private async getDashboardData(): Promise<User> {
    return await getDashboardData(this.getQueryString()).then((res) => {
      return res.data;
    });
  }

  private getCasesByUser(): void {
    const userList: DashboardUser[] = this.state.users;
    const casesByUser: DashboardCaseByUser[] = [];
    if (userList.length !== 0) {
      userList.forEach((user) => {
        getDashboardData(
            `QueryBy=classification&status=open&userId=${user.id}`
          )
          .then((res) => {
            if (res !== undefined) {
              casesByUser.push({
                userId: user.id,
                userName: user.label,
                totalCases: res.data[0].totalCases,
              });
              if (casesByUser.length === userList.length) {
                this.setState({
                  casesByUser: getAlphabetisedDashboardCasesByUsersList(
                    casesByUser
                  ),
                });
              }
            }
          });
      });
    }
  }

  getDatePeriod(): string {
    if (this.state.filters.status === "open") return "";

    let startDate = "";
    let endDate = "";
    const today = new Date();
    const formattedTodayString = today.toISOString().substring(0, 10);

    switch (this.state.filters.period) {
        case "today":
            startDate = formattedTodayString;
            endDate = formattedTodayString;
        break;
        case "current-month":
            startDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().substring(0, 10);
            endDate = formattedTodayString;
        break;
        case "last-month":   
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString().substring(0, 10);
            //Day 0 of this month is apparently the last day of last month, according to Javascript.
            endDate = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().substring(0, 10);
        break;
      default:
        break;
    }

    return startDate !== "" || endDate !== ""
      ? `&startdate=${startDate}&enddate=${endDate}`
      : "";
  }

  getQueryString(): string {
    let queryString = "";
    this.state.selectedACDs.map((acd: any) => {
      queryString +=
        queryString !== "" ? `&acdId=${acd.id}` : `acdId=${acd.id}`;
      return null;
    });
    this.state.selectedUsers.map((user: any) => {
      queryString +=
        queryString !== "" ? `&userId=${user.id}` : `userId=${user.id}`;
      return null;
    });

    const status =
      this.state.filters.status !== "all"
        ? `&status=${this.state.filters.status}`
        : "";

    queryString += queryString !== "" ? "&" : "";

    queryString += `QueryBy=${
      this.state.filters.type
    }${status}${this.getDatePeriod()}`;

    return queryString;
  }

  async getActiveUsers(): Promise<DashboardUser[]> {
    return await getActiveUsers().then((res) => {
      return _.map(res.data, (d) => {
        return { id: d.id, label: `${d.firstName} ${d.lastName}` };
      });
    });
  }

  loadData = async (): Promise<void> => {
    this.setState({ isLoading: true });
    this.setState(
      {
        dashboardData: await this.getDashboardData(),
        acds: await this.getAcds(),
        users: await this.getActiveUsers(),
      },
      () => {
        this.getCasesByUser();
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  private restoreFilters(func: any): void {
    const storeData = localStorage.getItem("dashboardfilters");
    if (storeData) {
      const data = JSON.parse(storeData);
      this.setState(
        {
          selectedACDs: data.selectedACDs,
          selectedUsers: data.selectedUsers,
          filters: data.filters,
        },
        func
      );
    } else {
      func();
    }
  }

  setFilters = (name: string, value: string): void => {
    const filters = this.state.filters;
    filters[name] = value;

    this.setState({ filterts: filters }, () => {
      this.updateData();
      this.storeFilters();
    });
  };

  private storeFilters(): void {
    const data = {
      selectedACDs: this.state.selectedACDs,
      selectedUsers: this.state.selectedUsers,
      filters: this.state.filters,
    };

    localStorage.setItem("dashboardfilters", JSON.stringify(data));
  }

  updateData = async (): Promise<void> => {
    this.setState({ isLoading: true }, async () => {
      this.setState(
        {
          dashboardData: await this.getDashboardData(),
        },
        () => this.setState({ isLoading: false })
      );
    });
  };

  render(): JSX.Element {
    const dashboardContext: DashboardContext = {
      state: this.state,
      actions: {
        changeACDs: this.changeACDs,
        changeUsers: this.changeUsers,
        setFilters: this.setFilters,
        clearData: this.clearData,
      },
    };

    return (
      <Context.Provider value={dashboardContext}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const DashboardConsumer = Context.Consumer;
