export interface RowMappingInputData {
  caseIdAndSpamStatus: JSX.Element;
  caseRef: string;
  classification: string;
  clientID: string;
  groupCode: string;
  confidence: string;
  createdDate: Date;
  description: string;
  from: string;
  id: string;
  owner: string;
  readonly: boolean;
  status: string;
  subject: string;
  team: string;
  timeRemaining: JSX.Element;
  clientCaseId: string | undefined;
}

type Content = {
  caseId: JSX.Element;
  classification: string;
  clientID: string;
  groupCode: string;
  clientCaseId: string|undefined,
  confidence: string;
  date: string;
  description: string;
  from: string;
  owner: string;
  status: string;
  subject: string;
  team: string;
  timeRemaining: JSX.Element;
};

export interface RowMappingOutputData {
  caseRef: string;
  data: Content;
  id: string;
  readonly: boolean;
}

export const mapCaseDataToRowData = (
  {
    caseIdAndSpamStatus,
    caseRef,
    classification,
    clientID,
    groupCode,
    confidence,
    createdDate,
    description,
    from,
    id,
    owner,
    readonly,
    clientCaseId,
    status,
    subject,
    team,
    timeRemaining,
  }: RowMappingInputData
): RowMappingOutputData => {
  const content: Content = {
    caseId: caseIdAndSpamStatus,
    clientID,
    groupCode,
    clientCaseId,
    timeRemaining,
    from,
    subject,
    description,
    classification,
    team,
    status,
    owner,
    confidence,
    date: createdDate.toLocaleString(),
  };

  return {
    id,
    readonly,
    caseRef,
    data: content,
  };
};
