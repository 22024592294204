import axios, { AxiosError, AxiosResponse } from "axios";
import { Group, PutTeamAccess, Team, TeamAccess, UnsavedGroup, UserRequest } from "../models";
import { ResponseData } from "../models/data";

import {
  apiUrl,
  axiosConfig,
} from "./shared/dataServiceHelpers";

const putDataByUrl = (
  url: string,
  data?: unknown
): Promise<ResponseData<unknown>> => {
  return axios
    .put(`${apiUrl}${url}`, data, axiosConfig())
    .then((axiosRes: AxiosResponse) => {
      const response: ResponseData<unknown> = axiosRes?.data;
      if (!response.success)
        throw response.message || "Unknown error while updating data";
      return response as ResponseData<unknown>;
    })
    .catch((error: AxiosError) => { 
      console.error(error);
      throw "Error while updating data";
    });
};

export const putApplyIncomingEmailAddressRule = (
  ruleId: number
): Promise<ResponseData<boolean>> => {
  return putDataByUrl("emailaccounts/putapplyincomingemailaddressrule/"+ ruleId) as Promise<
    ResponseData<boolean>
  >;
};

export const putGroup = (groupId: number, group: UnsavedGroup): Promise<ResponseData<Group>> => {
  return putDataByUrl(`groups/putgroup/${groupId}`, group) as Promise<ResponseData<Group>>;
} 

export const putSetting = <T>(path: string, id: number, data: T): Promise<ResponseData<T>> => {
  return putDataByUrl(`${path}/put/${id}`, data) as Promise<ResponseData<T>>;
}

export const putTeam = (
  data: Team
): Promise<ResponseData<Team>> => {
  return putDataByUrl(`teams/updateteam/${data.teamId}`, data) as Promise<ResponseData<Team>>;
};

export const putTeamAccesses = (
  teamAccesses: PutTeamAccess[]
): Promise<ResponseData<TeamAccess[]>> => {
  return putDataByUrl(`teamaccesses/putteamaccesses`, teamAccesses) as Promise<
    ResponseData<TeamAccess[]>
  >;
};

export const putUser = (
  id: number,
  data: UserRequest
): Promise<ResponseData<void>> => {
  return putDataByUrl(`users/put/${id}`, data) as Promise<ResponseData<void>>;
};
