import { TeamUser } from "./team";

export enum Role {
  Manager = 0,
  SystemAdmin = 1,
  RecsStatements = 2,
}

interface NameAndAddress {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export interface UserNameAddress extends NameAndAddress {
  id: number;  
}

export interface User extends NameAndAddress {
  id: number;
  azureId: string;
  hasTransferFormsGroupAccess: boolean;
  roles: Role[];
  teams: TeamUser[];  
  deactivated: boolean;
}

export interface UserRequest extends NameAndAddress {
  roles: Role[];
  teamIds: number[]; 
}

export interface UserData {
  user: User|null;
  userImage?: string;
  users: UserNameAddress[];
  savedFilters: SavedFilter[];
}

export interface UserActions {
  saveFilter: (queryString: any, filterName: string) => void;
  deleteFilter: (filterId: any) => void;
}

export interface UserContext {
  state: UserData;
  actions: UserActions;
}

export interface SavedFilter {
  azureId: string;
  filterId: number;
  filterName: string;
  queryString: string;
}