import React, { FC } from "react";

type Props = {
  disabled?: boolean;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  value: string;  
};

const TextInput: FC<Props> = ({disabled, label, name, onChange, value}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        disabled={disabled}
        type="text"
        className="form-control"
        name={name}
        id={name}
        value={value}
        onChange={(event):void => onChange(event.currentTarget.value)}
      />
    </div>);
};
export default TextInput;