import React, { FC } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormControl } from "@material-ui/core";
import { InputChangeEvent } from "./types";
import { FilterName } from "../../../../context/teamQ/reducers/filtersReducer";
import { useTeamQContext } from "../../../../context/teamQContextProvider";

export type selectOptionsType = { id: string; label: string };
interface Props {
  canHide: boolean;
  filterName: FilterName;
  handleFilter: (event: InputChangeEvent) => void;
  handleHide: (name: FilterName) => void;
}

export const TextInput: FC<Props> = ({ canHide, filterName, handleFilter, handleHide }) => {

  const {
    state: { filters },
  } = useTeamQContext();
  
  const filter = filters[filterName];

  if (!filter.show) return null;

  return (
    <div className="form-group">
      <label htmlFor={filterName}>{filter.label}</label>
      <div className="row">
        {canHide && 
          <div 
            className="col-2 pl-2 pt-2"
            role="button"
            aria-label={`Hide ${filter.label} filter`}
          >
            <ClearOutlinedIcon 
              onClick={()=>handleHide(filterName)}
            /> 
        </div>}
        <FormControl className={canHide ? "col-9": "col-11 pl-3"}>        
          <input
            type="text"
            id={filterName}
            className="form-control"
            placeholder={filter.label}
            name={filterName}
            value={filter.value}
            onChange={(e): void => handleFilter(e as InputChangeEvent)}
          />
        </FormControl>
      </div>
    </div>
  );
};
