/* eslint-disable max-lines */
import React, { Component, createContext, useContext } from "react";
import { Redirect } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as _ from "lodash";

import HubService from "../services/hub.service";
import { getACDs, getAttachments, getCaseEmail, getCasesById, getUsersByTeam } from "../services/get.data.service";
import { CaseStatus } from "../portal/pages/details/helpers/caseStatus";
import { CaseOverviewItem, CasesDetailsContext, CaseState } from "./casesDetails/types";
import { Email, FullCase } from "../models";
import { dbDateStringToLocalDate } from "../portal/shared/helpers/dates";
import validateEmailAddress from "../portal/shared/helpers/validateEmailAddress";

export interface Props {
  caseId: string;
  children: React.ReactNode;
}

const Context = createContext<CasesDetailsContext>({} as CasesDetailsContext);

export const useCaseContext = (): CasesDetailsContext => useContext(Context);

export class CasesDetailsContextProvider extends Component<Props> {
  state: CaseState = {
    caseStatus: CaseStatus.unClaimed,
    case: null,
    emails: [],
    notes: [],
    logs: [],
    hubConnection: new HubService("CaseHub"),
    redirect: null,
    overview: [],
    users: [],
    acds: [],
    attachments: [],
    isEmailAttachmentsOpen: false,
    isDocumentsAttachmentsOpen: false,
    emailSelectedAttachments: [],
    draftEmail: {},
    errorMessage: "",
    selectedEmail: undefined,
    emailTab: [],
    isEmailRulesOpen: false,
  };

  async componentDidMount(): Promise<void> {
    const data = this.loadData();

    if (data !== null) {
      await this.state.hubConnection.start();
      this.state.hubConnection.send("SubscribeToCase", this.props.caseId);

      this.state.hubConnection.listen("EmailSent", (requestId: string) => {
        this.deleteMessage(requestId, "email");
      });

      this.state.hubConnection.listen("NoteSaved", (requestId: string) => {
        this.deleteMessage(requestId, "note");
      });

      this.state.hubConnection.listen("CaseUpdated", () => {
        this.loadData();
      });
    }
  }

  componentWillUnmount(): void {
    this.deleteAllMessages();
    this.state.hubConnection.send("UnsubscribeFromCase", this.props.caseId);
  }

  assignUser = (userId: number, note: string): void => {
    const newNote: any = {
      note,
      requestId: uuid(),
      caseReference: this.props.caseId,
    };
    this.state.hubConnection.send("AddNote", newNote);
    this.state.hubConnection.send("AssignCases", {
      userId: Number(userId),
      caseReferences: [this.props.caseId],
    });
  };

  callAction = (message: string): void => {
    this.state.hubConnection.send(message, this.props.caseId);
  };

  caseSetNotSpam = (sendAcknowledgement: boolean): void => {
    const spamData = {
      caseReference: this.state.case?.caseReference,
      spamStatus: "NotSpam",
      sendAcknowledgement,
    };
    this.state.hubConnection.send("SetSpamStatus", spamData);
  };

  closeCase = (note: string): void => {
    if (note.length) {
      const newNote = {
        note,
        requestId: uuid(),
        caseReference: this.props.caseId,
      };
      this.state.hubConnection.send("AddNote", newNote);
    }
    this.state.hubConnection.send("CloseCase", {
      caseReference: this.props.caseId,
    });
  };

  confirmAsSpam = (): void => {
    const spamData = {
      caseReference: this.state.case?.caseReference,
      spamStatus: "Spam",
    };
    this.state.hubConnection.send("SetSpamStatus", spamData);
  };

  private deleteAllMessages(): void {
    const localData = localStorage.getItem("localstore");
    if (localData) {
      localStorage.removeItem("localstore");
    }
  }

  private deleteMessage(requestId: string, type: string): void {
    const localData = localStorage.getItem("localstore");
    if (localData) {
      let newData: any = {};

      newData = JSON.parse(localData);
      const removeIndex = _.findIndex(
        newData[type],
        (o: any) => o.requestId === requestId
      );

      if (newData[type]) {
        newData[type].splice(removeIndex, 1);
      }

      localStorage.setItem("localstore", JSON.stringify(newData));
    }
  }

  draftEmailUpdate = (type: string, value: any): void => {
    const draft = { ...this.state.draftEmail };
    draft[type] = value;
    this.setState({ draftEmail: draft });
  };

  emailRulesModalOpen = (state: boolean): void => {
    this.setState({ isEmailRulesOpen: state });
  };

  emailRulesModalSave = (data: any): void => {
    this.emailRulesModalOpen(false);

    const spamData = {
      caseReference: this.state.case?.caseReference,
      spamStatus: "Spam",
      ...data,
    };

    this.state.hubConnection.send("SetSpamStatus", spamData);
  };

  private getCaseStatus(caseData: FullCase): CaseStatus {
    if (caseData.status === "Closed") return CaseStatus.closed;

    if (!caseData.owner) return CaseStatus.unClaimed;

    if (caseData.requesterIsOwner) {
      return !caseData.client
        ? CaseStatus.claimedToMeNotValid
        : CaseStatus.claimedToMe;
    }

    return CaseStatus.claimed;
  }

  private attachmentsUploaded = (note?: string): void => {
    if (note) {
      this.sendNote(note);
    }

    if (this.state.case) {
      getAttachments(this.props.caseId).then((res) => {
        this.setState({attachments: res.data});
      });
    }
  };

  private async loadData(): Promise<FullCase | null> {
    return await getCasesById(this.props.caseId).then(async (res) => {
      if (res === null) {
        this.setState({ redirect: "/teamq" });
        return null;
      // eslint-disable-next-line max-lines
      }
      getCaseEmail(this.props.caseId).then((res: any) => {
        const s: any = {
          emailTab: [...this.restoreLocal("email"), ...res.data],
        };
        if (!this.state.selectedEmail) {
          s["selectedEmail"] = res.data[0];
        }

        this.setState(s);
      });

      getUsersByTeam(res.data.teamId).then((userres) => {
        this.setState({users: userres.data});
      });

      getACDs().then((acdres) => {
        this.setState({acds: acdres.data.filter((a: any) => a.clientId !== res.data.client?.id)});
      });

      getAttachments(this.props.caseId).then((res) => {
        this.setState({attachments: res.data});
      });
      
      const overview: CaseOverviewItem[] = [];

      const emails = [...res.data.emails, ...this.restoreLocal("email")];
      const notes = [...res.data.notes, ...this.restoreLocal("note")];

      if (emails) {
          emails.forEach((email) => {
            let date: Date;
            let external: string;
              if (email.emailDirection === "Sent") {
                  date = dbDateStringToLocalDate(email.emailDate)
                  external = `Internal ${date.toLocaleTimeString()}`
              } else {
                  date = dbDateStringToLocalDate(email.ingestDate)
                  external = `External ${date.toLocaleTimeString()}`
              }
          const data: CaseOverviewItem = {
            type: email.emailDirection === "Sent" ? "primary" : "default",
            content: email.textMessage,
            date,
            position: email.emailDirection === "Sent" ? "right" : "left",
            external: email.sending ? "Sending..." : external,
            sending: email.sending ? "sending" : "",
          };
          overview.push(data);
        });
      }

      if (notes) {
          notes.forEach((note) => {
          const date: Date = dbDateStringToLocalDate(note.noteDate);
          const eDate = date.toLocaleTimeString();
          const data: CaseOverviewItem = {
            type: "text",
            content: note.note,
            date: note.noteDate,
            position: "right",
            external: `${note.user} - ${eDate}`,
            sending: note.sending ? "sending" : "",
          };
          overview.push(data);
        });
      }
 
      let toAddressArray: string[] = [];
      if (emails.length > 0) {
        toAddressArray = emails[0].toAddresses.split(";").filter((e: string) => e !== " " && !e.includes(emails[0].pollingEmailAddress));
        if (emails[0]?.emailDirection === "Received") {
          toAddressArray = [emails[0].fromAddress, ...toAddressArray];
        }
      }
      const newstate: CaseState = {
        ...this.state,
        caseStatus: this.getCaseStatus(res.data),
        case: res.data,
        emails: emails,
        notes: notes,
        overview: _.orderBy(overview, ["date"], ["asc"]),
        logs: res.data.logs,
        draftEmail: emails[0] ? {
          toAddress: toAddressArray,
          ccAddresses: emails[0].ccAddresses
            ? emails[0].ccAddresses.split(";").filter((e: any) => e !== " ")
            : [],
          subject: emails[emails.length - 1]?.subject,
          body: !this.state.draftEmail?.body ? "" : this.state.draftEmail.body,
          includeCaseHistory: true,
        } : null,
      };

      this.setState(newstate);

      return res.data;
    });
  }

  private restoreLocal(type: string): any {
    const localData = localStorage.getItem("localstore");
    let newData: any = {};

    if (localData === null) return [];
    newData = JSON.parse(localData);

    const data = _.filter(
      newData[type],
      (d: any) => d.caseReference === this.props.caseId
    );

    return data ? data : [];
  }

  selectEmail = (index: number): void => {
    this.setState({ selectedEmail: this.state.emailTab[index] });
  };

  sendEmail = (): boolean => {
    const email = { ...this.state.draftEmail };

    if (email.toAddress.length === 0) {
      this.setState({ errorMessage: "You must enter a To email" });
      return false;
    }

    let error = false;
    [...email.ccAddresses, ...email.toAddress].map((email: string) => {
      if (!validateEmailAddress(email)) {
        error = true;
      }
      return true;
    });

    if (error) {
      this.setState({ errorMessage: "Email address not valid" });
      return false;
    }

    this.setState({ errorMessage: "" });

    email["requestId"] = uuid();
    email["fromAddress"] = this.state.emails[0].pollingEmailAddress;
    email["caseReference"] = this.props.caseId;

    email["attachments"] = this.state.emailSelectedAttachments;
    email.ccAddress = email.ccAddresses.join(";");
    email.toAddress = email.toAddress.join(";");

    const lastMessage = this.state.emails.findLast((message: Email) => { 
      return message.messageId !== null && message.emailDirection  === "Received";
    });
    email["messageId"] = lastMessage?.messageId;
    this.state.hubConnection.send("SendEmail", email);
    const e = { ...email };
    e["emailDirection"] = "Sent";
    e["message"] = e.body;
    e["sending"] = true;
    this.storeLocal("email", e);

    this.setState({ draftEmail: {} });
    this.loadData();
    return true;
  };

  sendNote = (note: string): void => {
    const newNote: any = {
      note,
      requestId: uuid(),
      caseReference: this.props.caseId,
    };

    const n = { ...newNote };
    n["sending"] = true;
    this.storeLocal("note", n);
    this.loadData();

    this.state.hubConnection.send("AddNote", newNote);
  };

  setEmailAttachments = (attachments: any): void => {
    this.setState({ emailSelectedAttachments: attachments });
  };

  private storeLocal(type: string, data: any): void {
    const localData = localStorage.getItem("localstore");
    let newData: any = {};
    if (localData) {
      newData = JSON.parse(localData);
      if (newData[type]) {
        newData[type] = [...newData[type], data];
      } else {
        newData[type] = [data];
      }
    } else {
      newData[type] = [data];
    }

    localStorage.setItem("localstore", JSON.stringify(newData));
  }

  toggleDocumentsAttachments = (show: boolean): void => {
    this.setState({ isDocumentsAttachmentsOpen: show });
  };

  toggleEmailAttachments = (show: boolean): void => {
    this.setState({ isEmailAttachmentsOpen: show });
  };

  undoConfirmAsSpam = (): void => {
    const spamData = {
      caseReference: this.state.case?.caseReference,
      spamStatus: "PotentialSpam",
    };
    this.state.hubConnection.send("SetSpamStatus", spamData);
  };

  updateACD = (acdId: number, note: string): void => {
    const newNote: any = {
      note,
      requestId: uuid(),
      caseReference: this.props.caseId,
    };

    this.state.hubConnection.send("AddNote", newNote);
    this.state.hubConnection.send("SetCaseAcd", {
      acdId: acdId.toString(),
      caseReference: this.props.caseId,
    });
  };

  updateClassification = (classificationId: string, note: string): void => {
    const newNote: any = {
      note,
      requestId: uuid(),
      caseReference: this.props.caseId,
    };

    this.state.hubConnection.send("AddNote", newNote);
    this.state.hubConnection.send("ReclassifyCases", {
      classificationId: classificationId.toString(),
      caseReferences: [this.props.caseId],
    });
  };

  render(): JSX.Element {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const casesDetailsContext: CasesDetailsContext = {
      state: this.state,
      actions: {
        assignUser: this.assignUser,
        callAction: this.callAction,
        caseSetNotSpam: this.caseSetNotSpam,
        closeCase: this.closeCase,
        confirmAsSpam: this.confirmAsSpam,
        draftEmailUpdate: this.draftEmailUpdate,
        emailRulesModalOpen: this.emailRulesModalOpen,
        emailRulesModalSave: this.emailRulesModalSave,
        attachmentsUploaded: this.attachmentsUploaded,
        loadData: this.loadData,
        selectEmail: this.selectEmail,
        sendEmail: this.sendEmail,
        sendNote: this.sendNote,
        setEmailAttachments: this.setEmailAttachments,
        toggleDocumentsAttachments: this.toggleDocumentsAttachments,
        toggleEmailAttachments: this.toggleEmailAttachments,
        undoConfirmAsSpam: this.undoConfirmAsSpam,
        updateACD: this.updateACD,
        updateClassification: this.updateClassification,
      },
    };

    return (
      <Context.Provider value={casesDetailsContext}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const CasesDetailsConsumer = Context.Consumer;
