import React from "react";
import { FC, useState } from "react";
import validateEmailAddress from "../helpers/validateEmailAddress";

type Props = {
  addresses: string[];
  label: string;
  id: string;
  placeholder?: string;
  setAddresses: (addresses: string[]) => void;
};
export const AddressesInput: FC<Props> = ({addresses, id, label, placeholder, setAddresses}) => {
    const [ input, setInput ] = useState("");
    
    return (
      <div className="addresses-input">
        <label htmlFor={id}>{label} </label>
        <div className="email-addresses">
        {addresses?.map((email: string, index: number)=>{
          return (
            <div key={`${email}-${index}`} className="email-address-item">
              {email}
              <button 
                tabIndex={-1} 
                aria-label="Remove" 
                className="remove-button"
                onClick={()=>{
                  addresses.splice(index,1);
                  setAddresses([...addresses]);
                }}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only visually-hidden">Remove</span>
              </button>
            </div>
          );
        })}
        <input
          type="text"
          id={id}
          placeholder={placeholder}
          size={input.length > 30 ? input.length : 30}
          onChange={(e) =>{
            const value = e.target.value;
            const lastChar = value.slice(-1);
            if ([",",";"," "].includes(lastChar)) {
              const address = value.slice(0,-1);
              if (validateEmailAddress(address)) {
                setAddresses([...addresses, address]);
                setInput("");
              } else {
                setInput(address);
              }
            } else {
              setInput(value);
            }
          }}
          onBlur={(e) => {
            if (e.target.value.trim().length > 0) {
              setAddresses([...addresses, e.target.value]);
              setInput("");
            }
          }}
          value={input}
          />
        </div>
      </div>    
    );
};
export default AddressesInput;