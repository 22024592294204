import axios, { AxiosError, AxiosResponse } from "axios";
import { ResponseData } from "../models/data";

import {
  apiUrl,
  axiosConfig,
  showError,
  handleError,
} from "./shared/dataServiceHelpers";

const putDataByUrl = (
  url: string
): Promise<ResponseData<unknown>> => {
  return axios
    .delete(`${apiUrl}${url}`, axiosConfig())
    .then((axiosRes: AxiosResponse) => {
      const response: ResponseData<unknown> = axiosRes?.data;
      if (!response.success)
        showError(response.message || "Unknown error while deleting data");
      return response as ResponseData<unknown>;
    })
    .catch((error: AxiosError) => {
      handleError(error);
      return error.response?.data as ResponseData<unknown>;
    });
};

export const deleteUser = (
  id: number,
): Promise<ResponseData<void>> => {
  return putDataByUrl(`users/delete/${id}`) as Promise<ResponseData<void>>;
};
