import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Group, ResponseData, Role, Team, User, UserRequest } from "../../../../../models";
import { getGroupIdsForTeams, getGroups, getTeamsForGroups } from "../../../../../services/get.data.service";
import MultiInput from "../../shared/fields/multiInput";
import TextInput from "../../shared/fields/textInput";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";
import ToggleInput from "../../shared/fields/toggleInput";
import { useUserContext } from "../../../../../context/userContextProvider";
import { emailRegEx } from "../../../../shared/constants";
import { putUser } from "../../../../../services/put.data.service";
import { postUser } from "../../../../../services/post.data.service";

type Props = {
  existingUser?: User;
  isDuplicate: (value: string)=> boolean;
  load: ()=>void;
};

const UserForm: FC<Props> = ({ existingUser, isDuplicate, load }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isManager, setIsManager] = useState<boolean>(false);
  const [isSysAdmin, setIsSysAdmin] = useState<boolean>(false);
  // const [hasRecsStatementsAccess, setHasRecsStatementsAccess] = useState<boolean>(false);

  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [groupIdsSelected, setGroupIdsSelected] = useState<number[]>([]);

  const [teamsAvailable, setTeamsAvailable] = useState<Team[]>([]);
  const [teamIdsSelected, setTeamIdsSelected] = useState<number[]>([]);

  const [errors, setErrors] = useState<string[]>([]);

  const { state :{ user: loggedInUser }} = useUserContext();

  useEffect(() => {
    getGroups().then((response) => {
      setAllGroups(response.data);
    });
  }, []);

  useEffect(() => {
    if (groupIdsSelected.length) {
      getTeamsForGroups(groupIdsSelected).then((response) => {
        setTeamsAvailable(response.data);
        const teamsStillSelected = teamIdsSelected.filter((id: number)=>{
          return response.data.find((team)=>team.teamId === id) !== undefined;
        })
        setTeamIdsSelected(teamsStillSelected);
      });
    } else {
      setTeamsAvailable([]);
      setTeamIdsSelected([]);
    }
  }, [groupIdsSelected]);

  useEffect(() => {
    if (existingUser) {
      if (existingUser.teams.length) {
        const teamIds = existingUser.teams.map((team)=>team.teamId);
        setTeamIdsSelected(teamIds);
        getGroupIdsForTeams(teamIds).then((response)=>{
          setGroupIdsSelected(response.data);
        });
      }
      setEmailAddress(existingUser.emailAddress);
      setFirstName(existingUser.firstName);
      setLastName(existingUser.lastName);
      setIsManager(existingUser.roles.includes(Role.Manager));
      setIsSysAdmin(existingUser.roles.includes(Role.SystemAdmin));
      // setHasRecsStatementsAccess(existingUser.roles.includes(Role.RecsStatements));
    }
  }, [existingUser]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (emailAddress.trim().length === 0) {
      errorMessages.push("Email is required");
    } else if (!emailRegEx.test(emailAddress.trim())){
        errorMessages.push("Email is not a valid address");
    } else if (isDuplicate(emailAddress.trim())) {
        errorMessages.push("Email address has already been used");      
    }
    if (firstName.length === 0) {
      errorMessages.push("First Name is required");
    }
    if (lastName.length === 0) {
      errorMessages.push("Last Name is required");
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      const roles: Role[] = [];
      isManager && roles.push(Role.Manager);
      isSysAdmin && roles.push(Role.SystemAdmin);
      // hasRecsStatementsAccess && roles.push(Role.RecsStatements);
      const toSave: UserRequest = {
        emailAddress,
        firstName,
        lastName,
        roles: roles,
        teamIds: teamIdsSelected,
      };
      if (existingUser) {
        putUser(existingUser.id, toSave).then((result: ResponseData<void>)=>{
          if (result.success) {
            load();
            setRedirect(true);
          } else {
            errorMessages.push(`Failed to update User. ${result.message}`);
          }
        });
      } else {
        postUser(toSave).then((result: ResponseData<void>)=>{
          if (result.success) {
            load();
            setRedirect(true);
          } else {
            errorMessages.push(`Failed to update User. ${result.message}`);
          }
        });
      }
    }
  };

  if (redirect) return <Redirect to="/settings/users/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingUser ? "Edit User" : "Create User"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingUser?.emailAddress} />
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput 
              disabled={existingUser && existingUser.azureId?.length > 0}
              label="Email" 
              name="user-email" 
              onChange={setEmailAddress} 
              value={emailAddress} 
            />
            <TextInput label="First Name" name="user-first-name" onChange={setFirstName} value={firstName} />
            <TextInput label="Last Name" name="user-lst-name" onChange={setLastName} value={lastName} />
            <ToggleInput label="Is a Manager" name="user-is-manager" onChange={setIsManager} value={isManager} />
            {loggedInUser?.roles.includes(Role.SystemAdmin) &&
              <ToggleInput
                label="Is a System Admin"
                name="user-is-sysadmin"
                onChange={setIsSysAdmin}
                value={isSysAdmin}
              />
            }
            {/* <ToggleInput 
              label="Has access to the Reconciliation Statements" 
              name="user-has-recs-statements" 
              onChange={setHasRecsStatementsAccess} 
              value={hasRecsStatementsAccess} 
            /> */}
            <MultiInput
              label="Groups"
              name="groups"
              onChange={(ids): void =>{
                setGroupIdsSelected(ids);
              }}
              ids={groupIdsSelected}
              options={allGroups.map((group) => {
                return {
                  id: group.groupId,
                  label: group.businessName,
                };
              })}
            />
            <MultiInput
              label="Teams"
              name="teams"
              onChange={setTeamIdsSelected}
              ids={teamIdsSelected}
              options={teamsAvailable.map((team: Team) => {
                return {
                  id: team.teamId,
                  label: team.name,
                };
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserForm;
