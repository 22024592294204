import React from "react";
import { SettingsReducerAction, setValueAction } from "../reducer";
import "react-datepicker/dist/react-datepicker.css";
import { SearchPattern, TransferFormSearchRequest } from "../../../../../models/transferFormSearchPatterns";
import TextInput from "./textInput";
import ToggleInput from "./toggleInput";

type Props = {
  data: TransferFormSearchRequest;
  dispatch: React.Dispatch<setValueAction<TransferFormSearchRequest>>;
  label: string|JSX.Element;
  property: "attachmentNamePatterns"|"subjectPatterns";
};

export type InputProps = Omit<Props, "data"|"dispatch">;

const SearchPatternsInput = (
  props: Props
) => {
  const { data, dispatch, label, property } = props;
  const patterns = data[property];

  const dispatchAction = (value: SearchPattern[]): void => {
    const action: setValueAction<TransferFormSearchRequest> = {
      type: SettingsReducerAction.SET_VALUE,
      property,
      value: value
    };
    dispatch(action);
  }

  const onAdd = (): void => {
    const newPatterns = [...patterns];
    newPatterns.push({pattern: "", isCaseSensitive: false, isRegex: false});
    dispatchAction(newPatterns);
  };

  const onChange = (index:number, patternProperty: keyof SearchPattern, value: string|boolean): void => {
    const newPatterns = [...patterns];
    newPatterns[index] = {...newPatterns[index], [patternProperty]: value};
    dispatchAction(newPatterns);
  };

  const onDelete = (index:number): void => {
    const newPatterns = [...patterns];
    newPatterns.splice(index, 1);
    dispatchAction(newPatterns);
  };

  return (
    <div className="form-group">
      <div className="row m-0" style={{alignItems: "flex-end", flexWrap: "nowrap"}}>
        <div className="col-6">
          <label>{label}</label>
        </div>
        <div className="col-1" style={{minWidth: "fit-content"}}>
          <label>Case Sensitive</label>
        </div>
        <div className="col-1 pl-0" style={{minWidth: "5rem"}}>
          <label>Regex</label>
        </div>
        <div className="col-2 pl-1">
          <label>Action</label>
        </div>
      </div>
      {patterns.map((pattern, index) => {
        return (
          <div className="row m-0" key={index} style={{alignItems: "flex-end", flexWrap: "nowrap"}}>
            <div className="col-6">
             <TextInput
                label=""
                name={`${property}-pattern-${index}`}
                onChange={(value)=>onChange(index, "pattern", value)}
                value={pattern.pattern}
              />
            </div>
            <div className="col-1" style={{minWidth: "fit-content"}}>
              <ToggleInput
                label=""
                name={`${property}-case-${index}`}
                onChange={(value) => onChange(index, "isCaseSensitive", value)}
                value={pattern.isCaseSensitive}
              />
            </div>
            <div className="col-1" style={{minWidth: "fit-content"}}>
              <ToggleInput
                label=""
                name={`${property}-regex-${index}`}
                onChange={(value) => onChange(index, "isRegex", value)}
                value={pattern.isRegex}
              />
            </div>
            <div className="col-2" >
              <button 
                className="btn btn-primary btn-sm mb-4" 
                style={{ width: "50%" }}
                onClick={()=>onDelete(index)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      })}
      <div className="row m-0" >
        <div className="col-3">
          <button 
            className="btn btn-primary btn-sm"
            onClick={onAdd}
          >
            Add Pattern
          </button>
        </div>
      </div>
    </div>
  )
}
export default SearchPatternsInput;