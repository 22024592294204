import React, { createContext, FC, useContext, useEffect, useReducer, useState } from "react";

import { SummaryCase, ACD, Classification, Team, SendParams, Group } from "../models";
import HubService from "../services/hub.service";
import selectedCasesReducer from "./teamQ/reducers/selectedCasesReducer";
import casesInFlightReducer from "./teamQ/reducers/casesInFlightReducer";
import displaySettingsReducer, { displaySettingsInitialState } from "./teamQ/reducers/displaySettingsReducer";
import useHubListeners from "./teamQ/hooks/useHubListeners";
import { useLoadData } from "./teamQ/hooks/useLoadData";
import filtersReducer, { filtersInitialState } from "./teamQ/reducers/filtersReducer";
import { TeamQContext } from "./teamQ/types";

const Context = createContext<TeamQContext>({} as TeamQContext);

export const useTeamQContext = (): TeamQContext => useContext(Context);

export const TeamQContextProvider: FC = ({ children }) => {
  const [singleCaseHubConnection] = useState(new HubService("CaseHub"));
  const [casesHubConnection] = useState(new HubService("CasesHub"));

  const [acds, setAcds] = useState<ACD[]>([]);
  const [cases, setCases] = useState<SummaryCase[]>([]);
  const [classifications, setClassifications] = useState<Classification[]>([]);
  const [totalCasesCount, setTotalCasesCount] = useState<number>(0);
  const [myCasesCount, setMyCasesCount] = useState<number>(0);
  const [teams, setTeams] = useState<Team[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const [sendParams, setSendParams] = useState<SendParams|null>();
  const [dataLoad, setDataLoad] = useState(false);

  const [casesInFlight, casesInFlightDispatch] = useReducer(casesInFlightReducer, []);
  const [displaySettings, displaySettingsDispatch] = useReducer(displaySettingsReducer, displaySettingsInitialState);
  const [filters, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const [selectedCases, selectedCasesDispatch] = useReducer(selectedCasesReducer, []);

  const contextValue: TeamQContext = {
    state: {
      acds,
      cases,
      casesInFlight,
      classifications,
      displaySettings,
      filters,
      myCasesCount,
      selectedCases,
      teams,
      groups,
      totalCasesCount,
    },
    dispatch: {
      casesInFlight: casesInFlightDispatch,
      displaySettings: displaySettingsDispatch,
      filters: filtersDispatch,
      selectedCases: selectedCasesDispatch,
    },
    actions: {
      load: () => setDataLoad(true),
      send: (params) => setSendParams(params),
    },
  };

  useHubListeners(casesHubConnection, singleCaseHubConnection, casesInFlightDispatch, setDataLoad);

  useLoadData(
    contextValue,
    dataLoad,
    setAcds,
    setCases,
    setClassifications,
    setDataLoad,
    setTeams,
    setGroups,
    setTotalCasesCount,
    setMyCasesCount
  );

  useEffect(()=>{
    if (sendParams) {
      singleCaseHubConnection.send(sendParams.type, sendParams.payload);
      setSendParams(null);
    }
  }, [sendParams]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const TeamQConsumer = Context.Consumer;
