import React, { FC } from "react";
import {
  ThemeProvider,
  createTheme,
  TableContainer,
  Table,
  makeStyles,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import SettingsTableBody from "./tableBody";
import SettingsTableHeader from "./tableHeader";

type Props = {
  ariaLabel?: string;
  rows: any;
  headers: any;
  paginator?: JSX.Element;
  greyedOut?: boolean;
};

const SettingsTable: FC<Props> = ({
  ariaLabel,
  rows,
  headers,
  paginator,
  greyedOut,
}) => {
  const grey = greyedOut ? { 
    overrides: { 
      MuiTableCell: { 
        body: { 
          color: 'rgba(0, 0, 0, 0.6)' 
        } 
      } 
    } 
  } : {};
  const themeParams = {
    typography: {
      fontFamily: "Source Sans Pro, sans-serif",
    }
  };
  const theme = createTheme({ ...themeParams, ...grey });

  const useStyles = makeStyles({
    table: {
      width: "100%",
    },
  });

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table aria-label={ariaLabel ? ariaLabel : "simple table"} className={classes.table}>
          <SettingsTableHeader headers={headers}></SettingsTableHeader>
          <SettingsTableBody rows={rows}></SettingsTableBody>
          {paginator && (
            <TableFooter>
              <TableRow id="footer">
                {paginator}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};
export default SettingsTable;
