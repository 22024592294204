import { useEffect } from "react";
import { ResponseData } from "../../../../../models";
import HubService from "../../../../../services/hub.service";


const useLoadData = (
  dataService: () => Promise<ResponseData<unknown[]>>,
  updateData: (data: unknown[]) => void,
  hub?: HubService,
  listen?: string[]
): void => {
  useEffect(() => {
    const loadData = (): void => {
      dataService().then((response) => {
        updateData(response.data);
      });
    };

    loadData();
    hub && hub.start().then(() => {
      listen && listen.forEach((event: string): void => {
        hub.listen(event, () => {
          loadData();
        });
      });
    });
    return (): void => {
      hub && hub.stop();
    };
  }, []);
};
export default useLoadData;