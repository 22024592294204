export enum SettingsReducerAction {
  LOAD,
  SET_VALUE,
}

export type loadAction<T> = {
  type: SettingsReducerAction.LOAD;
  value: T;
};

export type setValueAction<T> = {
  type: SettingsReducerAction.SET_VALUE;
  property: keyof T;
  value: unknown;
};

const settingsReducer = <T>() =>(
  state: T,
  action: loadAction<T> | setValueAction<T>
) => {
  switch (action.type) {
    case SettingsReducerAction.LOAD: {
      return {...action.value};
    }
    case SettingsReducerAction.SET_VALUE: {
      const mutableState = { ...state } as Record<keyof T,any>;
      mutableState[action.property] = action.value;
      return mutableState as T;
    }
  }
}; 
export default settingsReducer;