import React, { FC, useEffect, useState } from "react";
import { Classification, ResponseData } from "../../../models";
import { NewCase, NewCaseAttachment } from "../../../models/newCase";
import { PollingAddress } from "../../../models/pollingAddress";
import { getClassificationsForUser, getPollingAddressesForUser } from "../../../services/get.data.service";
import { postNewCase } from "../../../services/post.data.service";
import NewCaseForm from "../../pages/teamq/newCaseForm";
import AttachmentUpload from "../components/attachmentUpload";
import CommonModal from "../components/commonModal";
import { emailRegEx } from "../constants";
import { SendEmailModal } from "./sendEmailModal";
import ModalButtons from "./shared/modalButtons";
import isInternalMail from "../helpers/isInternalMail";

interface Props {
  handleClose: () => void;
  handleRedirect: (path: string) => void;
  show: boolean;
}

export const NewCaseModal: FC<Props> = ({ handleClose, handleRedirect, show }) => {
  const [isEmailAttachmentsOpen, setIsEmailAttachmentsOpen] = useState<boolean>(false);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pollingAddresses, setPollingAddresses] = useState<PollingAddress[] | null>(null);
  const [classifications, setClassifications] = useState<Classification[] | null>(null);
  const [pollingAddressId, setPollingAddressId] = useState<string>("");
  const [classificationId, setClassificationId] = useState<string>("");
  const [toAddresses, setToAddresses] = useState<string[]>([]);
  const [ccAddresses, setCcAddresses] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<NewCaseAttachment[]>([]);
  const [initialFiles, setInitialFiles] = useState<File[]>([]);
  const topOfContentRef = React.useRef<HTMLDivElement>(null);
 
  
  useEffect(()=>{
    if (show) {
      getClassificationsForUser().then((res)=>{
        setClassifications(res.data);
        if (res.data.length === 1) {
          setClassificationId(res.data[0].classificationId.toString());
        }
      });
      getPollingAddressesForUser().then((res)=>{
        setPollingAddresses(res.data);
        if (res.data.length === 1) {
          setPollingAddressId(res.data[0].emailAccountId.toString());
        }
      });
    }
  }, [show]);

  useEffect(()=>{
    if (errors) {
      topOfContentRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  const validate = (): string[] => {
    const errors: string[] = [];
    if (!pollingAddressId) {
      errors.push("From address is required");
    }
    if (classificationId === "") {
      errors.push("Classification is required");
    }
    if (toAddresses.length === 0) {
      errors.push("To address is required");
    } else if (toAddresses.find((address) => !emailRegEx.test(address))) {    
      errors.push("To address is not a valid email");
    }    
    if (ccAddresses.length > 0 && ccAddresses.find((address) => !emailRegEx.test(address))) {
      errors.push("CC address is not a valid email");
    }
    if (pollingAddresses) {
      const pollingAddressDetails = pollingAddresses.find((address) => address.emailAccountId === Number(pollingAddressId));
      if (toAddresses.find((address)=>
        address.toLowerCase() === pollingAddressDetails?.emailAddress.toLowerCase())) {        
        errors.push("Cannot send email to yourself");
      } else if (ccAddresses.find((address)=>
        address.toLowerCase() === pollingAddressDetails?.emailAddress.toLowerCase())) {        
        errors.push("Cannot send email to yourself");
      }
    }
    if (subject === "") {
      errors.push("Subject is required");
    }
    if (message === "") {
      errors.push("Message is required");
    }
    return errors;
  };


  const save = async (): Promise<void> => {
    const errorMessages: string[] = validate();
    const toAddressesString = toAddresses.join("; ");
    const ccAddressesString = ccAddresses.join("; ");
    if (errorMessages.length === 0) {      
      if (!isAreYouSureOpen && !isInternalMail(toAddresses, ccAddresses)) {
        setIsAreYouSureOpen(true);
      } else {
        setIsLoading(true);
        const newCase: NewCase = {
          emailAccountId: Number(pollingAddressId),
          classificationId: Number(classificationId),
          toAddresses: toAddressesString,
          ccAddresses: ccAddressesString,
          subject,
          message,
          attachments
        };
        postNewCase(newCase).then((response: ResponseData<string>) => {
          const caseReference = response.data;
          if (response.success) {
            handleRedirect(`/case/${caseReference}`);
          } else {          
            setErrors(["Failed to Send Email"]);
          } 
          setIsLoading(false);
        })
        .catch(()=>{
          setErrors(["Failed to Send Email"]);
          setIsLoading(false);
        });
      }
    } else {
      setErrors(errorMessages);
    }
  };

  const handleUpload = async (files: File[]):Promise<void> =>{
    setIsLoading(true);
    setInitialFiles(files);
    await Promise.all(files.map((file: File): void=>{ 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
          setAttachments(prev => {
            if (reader.result !== null) {
              return [...prev, {
                fileName: file.name,
                fileType: file.type ? file.type : "application/octet-stream",
                base64Contents: reader.result.toString().split(',')[1],
              }];
            }
            return prev;
        });
      };
      reader.onerror = (error): void => {
        setAttachments([...attachments, {
          fileName: `Failed to read attachment ${error}` ,
          fileType: "text/plain",
          base64Contents: "",
        }]);
        Promise.reject();
      };
    }));  
    setIsLoading(false);
    setIsEmailAttachmentsOpen(false);
  }

  const openEmailAttachments = ():void => {
    setAttachments([]);
    setIsEmailAttachmentsOpen(true);
  }

  const close = (): void => {
    setIsLoading(false);
    if (isEmailAttachmentsOpen) {
      setIsEmailAttachmentsOpen(false);
    } else {
      setPollingAddressId("");
      setClassificationId("");
      setToAddresses([]);
      setSubject("");
      setMessage("");
      setAttachments([]);
      setIsEmailAttachmentsOpen(false);
      setErrors([]);
      handleClose();
    }
  };

  const errorComponent = errors.length > 0 && (
    <ul>
      {errors.map((error: string, index: number) => (
        <li key={`error${index}`} className="alert alert-danger" role="alert" aria-label={error}>
          {error}
        </li>
      ))}
    </ul>
  );

  return (
    <CommonModal
      show={show}
      onHide={close}
      title="Add New Case"
      buttons={
        <ModalButtons 
          close={close} 
          disableSave={isLoading} 
          save={save} 
          saveLabel="Send Email" 
        />
      }
    >
      <div className="top-div" ref={topOfContentRef}></div>
      {errorComponent}

      {pollingAddresses && classifications && !isEmailAttachmentsOpen && (
        <NewCaseForm
          options={{ classifications, pollingAddresses }}
          state={{
            pollingAddressId,
            classificationId,
            toAddresses,
            ccAddresses,
            subject,
            message,
            attachments,
          }}
          setState={{
            setPollingAddressId,
            setClassificationId,
            setToAddresses,
            setCcAddresses,
            setSubject,
            setMessage,
            openEmailAttachments,
          }}
        />
      )}
      {isEmailAttachmentsOpen && 
        <AttachmentUpload 
          handleUpload={handleUpload}
          initialFiles={initialFiles}
        />
      }
      <SendEmailModal 
        show={isAreYouSureOpen} 
        handleClose={(): void => {
          setIsLoading(false);
          setIsAreYouSureOpen(false);
        }} 
        handleSave={save}
      />
    </CommonModal>
  );
};
