import { SettingsSaveDataSLA } from "./acd";
import { Attachment } from "./attachment";

export type SendParams =
  | SendAssignCases
  | SendAddNote
  | SendAddPollingAddress
  | SendClaimCases
  | SendClaimSingleCase
  | SendCloseCases
  | SendCloseSingleCase
  | SendCreateAcd
  | SendDeleteIncomingEmailRule
  | SendEmail
  | SendReclassifyCases
  | ResendToStudioCases
  | SendSetCaseId
  | SendSetSpamStatus
  | SendUnclaimCase
  | SendUpdateAcd
  | SendUpdatePollingAddress
  | SendUpsertAClassification
  | SendUpsertIncomingEmailRule

type SendAssignCases = {
  type: "AssignCases";
  payload: {
    userId: number;
    caseReferences: string[];
  };
};

type SendAddNote = {
  type: "AddNote";
  payload: {
    note: string;
    requestId: string;
    caseReference: string;
  };
};

type SendAddPollingAddress = {
  type: "AddPollingAddress";
  payload: {
    activelyPolling: boolean;
    emailAddress: string;
    clientId: number;
    groupId: number;
    caseRefPrefix: string;
  };
};

type SendClaimCases = {
  type: "ClaimCases";
  payload: string[];
};

type SendClaimSingleCase = {
  type: "ClaimCase";
  payload: string;
};

type SendCloseCases = {
  type: "CloseCases";
  payload: {
    clientId: number;
    caseReferences: string[];
  };
};

type SendCloseSingleCase = {
  type: "CloseCase";
  payload: {
    clientId: number|null;
    caseReference: string;
  };
};

type SendCreateAcd = {
  type: "CreateAcd";
  payload: {
    amberThresholdHours: number;
    clientCode: string;
    clientId?: number;
    clientName: string;
    slaHours: number;
    slas: SettingsSaveDataSLA[];
  }
};

type SendDeleteIncomingEmailRule = {
  type: "DeleteIncomingEmailRule"
  payload: {
    incomingEmailAccountRuleId: number;
  }
}

type SendEmail = {
  type: "SendEmail",
  payload: OutboundEmail,
};

type SendReclassifyCases = {
  type: "ReclassifyCases";
  payload: {
    caseReferences: string[];
    classificationId: string;
  };
};

type ResendToStudioCases = {
  type: "ResendToStudioCases";
  payload: {
    caseReferences: string[];
  };
};

type SendSetCaseId = {
  type: "SetCaseAcd";
  payload: {
    caseReference: string;
    acdId: string;
  };
};

type SendSetSpamStatus = {
  type: "SetSpamStatus";
  payload: {
    caseReference: string;
    spamStatus: SpamStatus;
    sendAcknowledgement: boolean;
    data?: EmailRulesData
  }
};

type SendUnclaimCase = {
  type: "UnclaimCase";
  payload: string;
};

type SendUpdateAcd = {
  type: "UpdateAcd";
  payload: {
    amberThresholdHours: number;
    clientCode: string;
    clientId: number;
    clientName: string;
    slaHours: number;
    slas: SettingsSaveDataSLA[];
  }
};

type SendUpsertAClassification = {
  type: "UpsertAClassification";
  payload: {
    classificationId?: number;
    name: string;
  }
};

type SendUpdatePollingAddress = {
  type: "UpdatePollingAddress";
  payload: {
    emailAccountId: number;
    activelyPolling: boolean;
    emailAddress: string;
    clientId: number;
    groupId: number;
    pollFrom: Date|null;
    caseRefPrefix: string;
  }
};

type SendUpsertIncomingEmailRule = {
  type: "UpsertIncomingEmailRule";
  payload: {
    incomingEmailAccountRuleId?: number;
    emailAddress: string;   
    subject: string;           
    classificationId: number|null;
    clientId: number|null;
    suppressAutoResponse: boolean;
    autoClose: boolean;
  }
}

export type OutboundEmail = {
  requestId: string;
  toAddresses: string;
  ccAddress: string;
  fromAddress: string;
  subject: string;
  body: string;
  caseReference: string;
  includeCaseHistory: boolean;
  messageId: string;
  attachments: Attachment[];
}

export type EmailRulesData = {
  emailAddress: string;
  classificationId: number|null;
  clientId: number|null;
  suppressAutoResponse: boolean;
  autoClose: boolean;
};

export enum SpamStatus {
  spam = "Spam",
  notSpam = "NotSpam",
  potentialSpam = "PotentialSpam",
}
