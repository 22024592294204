
const isInternalMail = (toAddresses: Array<string>, ccAddresses: Array<string>): boolean => {    
  
  if (toAddresses?.find((address: string)=>{
    return !address.toLowerCase().includes("@fnz.");
  })) {
    return false;
  }
  
  if (!ccAddresses) {
   return true;
  }
  
  if(ccAddresses.find((address: string)=>{
    return !address.toLowerCase().includes("@fnz.");
  })) {
    return false;
  }

  return true;
}
export default isInternalMail;