import React, { FC, useEffect, useReducer, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import TransferFormSearchPatternsForm from "./transferFormSearchPatternsForm";
import { TransferFormSearchPatterns, TransferFormSearchRequest } from "../../../../../models/transferFormSearchPatterns";
import settingsReducer, { SettingsReducerAction } from "../../shared/reducer";
import { initialState } from "./initialState";
import { FormInfo } from "../../shared/types";
import { getSettings } from "../../../../../services/get.data.service";
import { putSetting } from "../../../../../services/put.data.service";
import SettingsTitle from "../../shared/settingsTitle";
import SearchPatternsInput, { InputProps } from "../../shared/fields/searchPatternsInput";

const TransferFormSearchPatternsSettings: FC = () => { 
  const { action, id }: SettingsParams = useParams();
  const [ entity, entityDispatch ] = useReducer(settingsReducer<TransferFormSearchRequest>(), initialState);
  const [ formInfo, setFormInfo ] = useState<FormInfo>();
  const [ data, setData ] = useState<TransferFormSearchPatterns[]>([]);
  const [ messages, setMessages ] = useState<string[]>([]);
  const [redirect, setRedirect] = useState<boolean>(false);
  const path = "actionextracttransferformdata";
  const entityId = Number(id);   
    
  useEffect(() => {
    if(data && id){    
      const entity = entityId && data?.find((transferForm) => transferForm.id === entityId) || initialState;
      entityDispatch({type: SettingsReducerAction.LOAD, value: entity});
      setFormInfo({title: entityId ? entity.client : ""});
    }
  }, [data, action]);

  const inputField = (props: InputProps) => (
    <SearchPatternsInput
      {...props}
      data={entity}
      dispatch={entityDispatch}
    />
  ); 
  
  const loadData = ()=>{
    getSettings<TransferFormSearchPatterns>(path).then((response) => {
      setData(response.data as TransferFormSearchPatterns[]);
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  const submit = () => { 
    const errors: string[] = [];
    if (entity.attachmentNamePatterns.find((pattern) => pattern.pattern === "")) {
      errors.push("Attachment Name Patterns cannot be empty");
    }
    if (entity.subjectPatterns.find((pattern) => pattern.pattern === "")) {
      errors.push("Subject Patterns cannot be empty");
    }
    if (errors.length > 0) {
      setMessages(errors);
      return false;
    }
    return putSetting(path, entityId, entity)
      .then((result)=>{
        if (result.success && result.data) {
          setMessages(["Saved successfully"]);
          setRedirect(true);
          return true;
        } else {
          setMessages([`Failed to update. ${result.message}`]);
          return false;
        }
      })
      .catch((error)=>{
        setMessages([error]);
        return false;
      });
  };

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((transferForm) => {
      return {
        id: transferForm.id,
        data: [
          transferForm.client,
          <Link key={`lis-${transferForm.id}`} to={`/system-settings/transferformsearchpatterns/edit/${transferForm.id}`}>
            Edit
          </Link>,
        ],
      };
    });
    
    
  if (redirect) return <Redirect to="/system-settings/transferformsearchpatterns" />;
  
  return (
    <SettingsView
      headers={["Client", ""]} 
      rows={rows} 
      titleSingle="Transfer Form Search Pattern"
      titlePlural="Transfer Form Search Patterns"
      type={SettingsType.transferForms}
    >
    {data !== null && (
      <TransferFormSearchPatternsForm   
        inputField={inputField}
        formInfo={formInfo}
        title={
          <SettingsTitle errors={messages} title="Edit Transfer Form Search Patterns">
            <button 
              className="btn btn-primary float-right" 
              onClick={submit}
            >
              Save
            </button>
          </SettingsTitle>
        }
      />
    )}
    </SettingsView>
  );
};
export default TransferFormSearchPatternsSettings;
