import React, { useState } from "react";
import { useCaseContext } from "../../../../../context/casesDetailsContextProvider";
import { Checkbox } from "@material-ui/core";
import FileUploadModalSendEmail from "../../../../shared/actionModals/fileUploadModalSendEmail";
import { isMine } from "../../helpers/caseStatus";
import { SendEmailModal } from "../../../../shared/actionModals/sendEmailModal";
import isInternalMail from "../../../../shared/helpers/isInternalMail";
import AddressesInput from "../../../../shared/components/addressesInput";


export default function MessageInput() {
  const [show, setShow] = useState(false);
  const [closeCase, setCloseCase] = useState(false);
  const context = useCaseContext();

  const handleSave = (): void => {
   
     if ( !show && !isInternalMail(context.state.draftEmail.toAddresses, context.state.draftEmail.ccAddresses)) 
     {
        setShow(true)
      } 

     else
     {  
        setShow(false);
        const sent = context.actions.sendEmail();

        if (sent && closeCase) 
        {
          context.actions.closeCase("");
          setCloseCase(false);
        }
      }     
      }

  return (
    <>
      {isMine(context.state.caseStatus)  && !context.state.emails.some((email: any)=> email.sending) ? (
        <div className="email-input">
          <div className="content-container">
            <AddressesInput 
              addresses={context.state.draftEmail.toAddress}
              id="To-Addresses"
              label="To:"
              placeholder="Add To Address"
              setAddresses={(addresses: string[]) => context.actions.draftEmailUpdate("toAddress", addresses)}
            />
            <AddressesInput
              addresses={context.state.draftEmail.ccAddresses}
              id="CC-Addresses"
              label="CC:"
              placeholder="Add CC Address"
              setAddresses={(addresses: string[]) => context.actions.draftEmailUpdate("ccAddresses", addresses)}
            />
            <div className="flex-input">
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={context.state.draftEmail.subject}
                onChange={(e) => context.actions.draftEmailUpdate("subject", e.target.value)}
                className="input-white"
              />
            </div>
            {context.state.emailSelectedAttachments.length > 0 ? (
              <div className="flex-input">
                <label>Attachments: </label>
                <div className="attachments">
                  {context.state.emailSelectedAttachments.map((attachment: any, index: number) => (
                    <div key={index} className="attachment-pill rbt-token rbt-token-removeable">
                      {attachment.displayFileName ? attachment.displayFileName : attachment.fileName}
                      <button aria-label="Remove"
                        className="close rbt-close rbt-token-remove-button"
                        type="button"
                        onClick={()=>{
                          context.actions.setEmailAttachments(context.state.emailSelectedAttachments.filter((att)=> att.attachmentId != attachment.attachmentId))
                        }}
                      >
                        <span aria-hidden="true">×</span><span className="sr-only visually-hidden">Remove</span></button>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex-input body">
              <textarea
                aria-label="email body"
                name="body"
                value={context.state.draftEmail.body}
                onChange={(e) => context.actions.draftEmailUpdate("body", e.target.value)}
                className="input-white"
              ></textarea>
            </div>
          </div>
          <div className="email-actions">
            <div className="media">
              <button onClick={() => context.actions.toggleEmailAttachments(true)}>
                <i className="fas fa-paperclip"></i>
              </button>
            </div>
            <div className="save-button">
              <div className="history-check">
                Include history in reply?
                <Checkbox
                  checked={context.state.draftEmail.includeCaseHistory}
                  onClick={() =>
                    context.actions.draftEmailUpdate("includeCaseHistory", !context.state.draftEmail.includeCaseHistory)
                  }
                />
              </div>              
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(): void => {
                    setCloseCase(true);
                    handleSave();
                  }}
                >
                  Send Email
                </button>
              
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <SendEmailModal 
        show={show} 
        closeCase={closeCase} 
        handleClose={(): void => setShow(false)} 
        handleSave={handleSave}
      ></SendEmailModal>

      <FileUploadModalSendEmail
        handleClose={context.actions.toggleEmailAttachments}
        handleUpload={context.actions.attachmentsUploaded}
        show={context.state.isEmailAttachmentsOpen}
        url={`attachments/upload/${context.state.case?.id}`}
      / >
    </>
  );
}
