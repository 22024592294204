import React, { FC } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { InputChangeEvent } from "./types";
import { FilterName } from "../../../../context/teamQ/reducers/filtersReducer";
import { useTeamQContext } from "../../../../context/teamQContextProvider";

export type selectOptionsType = { id: string; label: string };
interface Props {
  canHide: boolean;
  filterName: FilterName;
  handleFilter: (event: InputChangeEvent) => void;
  handleHide: (name: FilterName) => void;
  options: selectOptionsType[];
}

export const SelectInput: FC<Props> = ({ canHide, filterName, handleFilter, handleHide, options }) => {

  const {
    state: { filters },
  } = useTeamQContext();
  
  const filter = filters[filterName];

  if (!filter.show) return null;
  
  return (
    <div className="form-group">
      <label htmlFor={filterName}>{filter.label}</label>
      <div className="row">
        {canHide && 
          <div
            className="col-2 pl-2 pt-2"
            role="button"
            aria-label="Hide filter"
          >
            <ClearOutlinedIcon
              role="button"
              aria-label={`Hide ${filter.label} filter`}
              onClick={()=>handleHide(filterName)} 
          /> 
        </div>}
        <FormControl className={canHide ? "col-9": "col-11 pl-3"}>
          <Select
            id={filterName}
            labelId={`${filterName}-label`}
            displayEmpty
            disableUnderline
            name={filterName}
            onChange={(e): void => handleFilter(e as InputChangeEvent)}
            value={filter.value}
          >
            <MenuItem value="">Select one</MenuItem>

            {options.map((item: selectOptionsType, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
