import React, { FC, useState } from "react";
import Cases from "./cases/cases";
import TeamQSidenav from "./teamQSidenav";
import TeamQTab from "./teamQTab";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { AssignUserModal } from "../../shared/actionModals/assignUserModal";
import { BulkCloseModal } from "../../shared/actionModals/bulkCloseModal";
import { ClassifyModal } from "../../shared/actionModals/classifyModal";
import {
  claimSelectedCases,
  handleBulkAssignSave,
  handleBulkCloseSave,
  handleBulkReclassifySave,
  handleResendCasesSave,
} from "./helpers/handleBulkActions";
import { displaySettingsAction, PageName } from "../../../context/teamQ/reducers/displaySettingsReducer";
import { selectedCasesAction } from "../../../context/teamQ/reducers/selectedCasesReducer";
import { useUserContext } from "../../../context/userContextProvider";
import { businessAreaSettings } from "../../helpers/businessAreaSettings";
import { NewCaseModal } from "../../shared/actionModals/newCaseModal";
import { Redirect } from "react-router";
import { ResendToStudioModal } from "../../shared/actionModals/resendToStudioModal";
import { Role } from "../../../models";

const TeamQ: FC = () => {
  const changeTab = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: PageName,
    setPage: (page: PageName) => void
  ): void => {
    event.preventDefault();
    setPage(page);
  };

  const [redirect, setRedirect] = useState<string>();
  const [showNewCase, setShowNewCase] = useState(false);
  const [showBulkAssign, setShowBulkAssign] = useState(false);
  const [showBulkReclassify, setShowBulkReclassify] = useState(false);
  const [showBulkCloseModal, setShowBulkClose] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false)

  const {
    state: { acds, displaySettings, filters, myCasesCount, selectedCases, totalCasesCount },
    dispatch: { displaySettings: displaySettingsDispatch, selectedCases: selectedCasesDispatch },
    actions: { send },
  } = useTeamQContext();

  const {state:{user}} = useUserContext();

  if (redirect) {
    return <Redirect push to={redirect} />;
  }
  return (
    <div className="teamq page-layout">
      <TeamQSidenav />
      <>
        <div className="content">
          <div className="action-buttons">
            <button className="btn btn-primary" onClick={(): void => setShowNewCase(true)}>
              Compose New Email
            </button>
            {(!businessAreaSettings().hideBulkClaim || user?.roles.includes(Role.Manager)) && (
              <button
                className="btn btn-primary"
                disabled={selectedCases.length < 1}
                onClick={(): void => {
                  claimSelectedCases(send, selectedCases);
                  selectedCasesDispatch({ type: selectedCasesAction.REMOVE_ALL });
                }}
              >
                Claim
              </button>
            )}
            {(!businessAreaSettings().hideBulkAssign || user?.roles.includes(Role.Manager)) && (
              <button
                className="btn btn-primary"
                disabled={selectedCases.length < 1}
                onClick={(): void => setShowBulkAssign(true)}
              >
                Reassign
              </button>
            )}
            {(!businessAreaSettings().hideBulkClassify || user?.roles.includes(Role.Manager)) && (
              <button
                className="btn btn-primary"
                disabled={selectedCases.length < 1}
                onClick={(): void => setShowBulkReclassify(true)}
              >
                Reclassify
              </button>
            )}
            {(!businessAreaSettings().hideBulkClose || user?.roles.includes(Role.Manager)) && (
              <button
                className="btn btn-primary"
                disabled={selectedCases.length < 1}
                onClick={(): void => setShowBulkClose(true)}
              >
                Close
              </button>
            )}
            {user?.roles.includes(Role.SystemAdmin) && (
              <button
                className="btn btn-primary"
                disabled={selectedCases.length < 1 || filters.studiosendfailed.value !== "true"}
                onClick={(): void => setShowResendModal(true)}
              >
                Resend cases to Studio
              </button>
            )}
          </div>

          <ul className="tabs">
            <TeamQTab
              changeTab={changeTab}
              page={displaySettings.pageName}
              setPage={(pageName: PageName): void =>
                displaySettingsDispatch({ type: displaySettingsAction.SET_PAGE, pageName })
              }
              route={PageName.GET_ALL_FOR_USER}
              title={"All Cases"}
              totalCases={totalCasesCount}
            />
            <TeamQTab
              changeTab={changeTab}
              page={displaySettings.pageName}
              setPage={(pageName: PageName): void =>
                displaySettingsDispatch({ type: displaySettingsAction.SET_PAGE, pageName })
              }
              route={PageName.GET_MY_CASES}
              title={"My Cases"}
              totalCases={myCasesCount}
            />
            <TeamQTab
              changeTab={changeTab}
              page={displaySettings.pageName}
              setPage={(pageName: PageName): void =>
                displaySettingsDispatch({ type: displaySettingsAction.SET_PAGE, pageName })
              }
              route={PageName.GET_UNCLAIMED}
              title={"Unclaimed Cases"}
            />
          </ul>

          <div className="content-container">
            <Cases />
          </div>
        </div>
        <NewCaseModal
          show={showNewCase}
          handleClose={(): void => setShowNewCase(false)}
          handleRedirect={(path: string): void => setRedirect(path)}
        >
        </NewCaseModal>
        <AssignUserModal
          show={showBulkAssign}
          handleClose={(): void => setShowBulkAssign(false)}
          handleSave={(userId: number, note: string): void => {
            setShowBulkAssign(false);
            handleBulkAssignSave(send, selectedCases, userId, note);
            selectedCasesDispatch({ type: selectedCasesAction.REMOVE_ALL });
          }}
        ></AssignUserModal>
        {/* selectedCases */}
        <ClassifyModal
          show={showBulkReclassify}
          handleClose={(): void => setShowBulkReclassify(false)}
          handleSave={(classificationId: number, note: string): void => {
            setShowBulkReclassify(false);
            handleBulkReclassifySave(send, selectedCases, classificationId, note);
            selectedCasesDispatch({ type: selectedCasesAction.REMOVE_ALL });
          }}
          selectedCases={selectedCases}
        ></ClassifyModal> 
        <BulkCloseModal
          show={showBulkCloseModal}
          clientList={acds}
          handleClose={(): void => setShowBulkClose(false)}
          handleSave={(clientId: number, note: string): void => {
            setShowBulkClose(false);
            handleBulkCloseSave(send, clientId, selectedCases, note);
            selectedCasesDispatch({ type: selectedCasesAction.REMOVE_ALL });
          }}
        ></BulkCloseModal>
        <ResendToStudioModal
          show={showResendModal} 
          handleClose={(): void => setShowResendModal(false)} 
          handleSave={(): void =>{
            setShowResendModal(false);
            handleResendCasesSave(send, selectedCases);
            selectedCasesDispatch({ type: selectedCasesAction.REMOVE_ALL});
          }}
        />
      </>
    </div>
  );
};
export default TeamQ;
