import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import EmailIcon from "@material-ui/icons/Email";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import TocIcon from '@mui/icons-material/Toc';

import { Sidenav } from "../../shared/sidenav/sidenav";


interface SystemSettingsSidenavLink {
  text: string;
  path: string;
  icon: JSX.Element;
}



export default function SystemSettingsSidenav() {
  const { path } = useRouteMatch();
  const links: SystemSettingsSidenavLink[] = [
    {
      text: "Groups",
      path: `${path}/groups`,
      icon: <WorkspacesIcon />,
    },
    {
      text: "Polling Email Accounts",
      path: `${path}/emailaccounts`,
      icon: <EmailIcon />,
    },
    {
      text: "Transfer Form Search Patterns",
      path: `${path}/transferformsearchpatterns`,
      icon: <TocIcon />,
    }
  ];

  return (
    <Sidenav>
      <ul className="sidenav-menu">
        {links.map((link, index) => {
          return (
            <li key={index}>
              <NavLink exact activeClassName="active" to={link.path}>
                <i className="material-icons">{link.icon}</i>
                {link.text}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Sidenav>
  );
}