import React, { FC } from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useUserContext } from "../../../context/userContextProvider";
import GroupSettings from "./pages/groupSettings/groupSettings";
import SystemSettingsSidenav from "./systemSettingsSidenav";
import PollingAddressSettings from "./pages/pollingAddressSettings/pollingAddressSettings";
import { Role } from "../../../models";
import TransferFormSearchPatternsSettings from "./pages/transferFormSearchPatternsSettings/transferFormSearchPatternsSettings";

const SystemSettings: FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const {state: {user}} = useUserContext();

  return (
    <>
      {user ?
        user.roles.includes(Role.SystemAdmin) ? (
          <div className="page-layout">
            <SystemSettingsSidenav />
            <div className="content">
              <Switch>
                <Route
                  path={`${path}/groups/:action/:id?`}
                  component={GroupSettings}
                />
                <Route
                  path={`${path}/emailaccounts/:action/:id?`}
                  component={PollingAddressSettings}
                />
                <Route
                  path={`${path}/transferformsearchpatterns/:action/:id?`}
                  component={TransferFormSearchPatternsSettings}
                />
                <Route
                  exact
                  path={`${path}/:type`}
                  key={location.pathname}
                >
                  <Redirect
                    to={`${location.pathname}${
                      location.pathname.endsWith("/") ? "" : "/"
                    }list`}
                  ></Redirect>
                </Route>
                <Route exact path={`${path}/`} key={location.pathname}>
                  <Redirect
                    to={`${path}/emailaccounts/list`}
                  ></Redirect>
                </Route>
              </Switch>
            </div>
          </div>
        ) : (
          <Redirect to="" />
      ) : (
        null
      )}
    </>
  )
}
export default SystemSettings;