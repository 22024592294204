import React, { FC, useState } from "react";
import { TableRow, TableCell, TableBody, Checkbox } from "@material-ui/core";
import { useTeamQContext } from "../../../../../context/teamQContextProvider";
import { selectedCasesAction } from "../../../../../context/teamQ/reducers/selectedCasesReducer";
import { hideCheckboxes } from "../../../../helpers/hideCheckboxes";
import { useUserContext } from "../../../../../context/userContextProvider";
import { Redirect } from "react-router-dom";
import { RowMappingOutputData } from "../../cases/mapCaseDataToRowData";
import { Role } from "../../../../../models";

type Props = {
  rows: RowMappingOutputData[];
};

const DataTableBody: FC<Props> = ({ rows }) => {
  const [redirect, setRedirect] = useState<string | null>(null);

  const {
    state: { selectedCases },
    dispatch: { selectedCases: selectedCasesDispatch },
  } = useTeamQContext();

  const {
    state: { user },
  } = useUserContext();

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <TableBody>
      {rows.map((row: RowMappingOutputData, index: number) => {
        const data = Object.entries(row.data);
        return (
          <TableRow hover role="row" tabIndex={-1} selected={row.readonly ? true : false} key={index}>
            {(!hideCheckboxes() || user?.roles.includes(Role.Manager)) &&(
              <TableCell padding="checkbox">
                <Checkbox
                  role="checkbox"
                  checked={selectedCases.includes(row.caseRef) && !row.readonly ? true : false}
                  onClick={(): void => {
                    if (!row.readonly) {
                      selectedCasesDispatch({
                        type: selectedCasesAction.TOGGLE_ONE,
                        caseReference: row.caseRef,
                      });
                    }
                  }}
                  disabled={row.readonly}
                />
              </TableCell>
            )}
            {data.map((key: [string, string | JSX.Element | undefined], index: number) => 
              <TableCell className="link" key={index} onClick={(): void => setRedirect(`case/${row.caseRef}`)}>
                  {key[1]}
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
