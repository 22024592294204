import React, { FC } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Switch } from "@material-ui/core";
import { FilterName } from "../../../../context/teamQ/reducers/filtersReducer";
import { useTeamQContext } from "../../../../context/teamQContextProvider";


export type selectOptionsType = { id: string; label: string };
interface Props {
  canHide: boolean;
  filterName: FilterName;
  handleFilter: (name: FilterName, value: boolean) => void;
  handleHide: (name: FilterName) => void;
}

export const BooleanInput: FC<Props> = ({ canHide, filterName, handleFilter, handleHide }) => {

  const {
    state: { filters },
  } = useTeamQContext();
  
  const filter = filters[filterName];

  if (!filter.show) return null;
  return (
    <div>
    <div className="row">
      {canHide && 
        <div 
          className="col-2 pl-2 pt-2"
          role="button"
              aria-label={`Hide ${filter.label} filter`}
        >
          <ClearOutlinedIcon 
            onClick={()=>handleHide(filterName)} 
        />
      </div>}
      <div className={canHide ? "col-10 pl-0": "col-11 pl-3"}> 
        <label htmlFor={filterName}>{filter.label}</label>
        <Switch
          id={filterName}
          name={filterName}
          checked={filter.value == "true"}
          onChange={
            (event):void => handleFilter(filterName, event.currentTarget.checked)
          }
        />
        </div>
    </div>
    </div>
  );
};
